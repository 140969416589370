.series {
	position: relative;
	background-color: var(--background);
}
::-webkit-scrollbar {
	display: none;
}
.series__title {
	padding: 10px;
}
.series__info-container {
	padding: 10px;
	width: 100%;
	color: var(--text);
	background-color: var(--background);
}
.series_main-info {
	padding: 10px;
	width: 100%;
	background: linear-gradient(#ffffff00, var(--background) 120px);
	position: absolute;
	bottom: 0;
	color: var(--text);
}
.series__image-wrapper {
	position: relative;
}
.series__mobile-options-container {
	display: flex;
	justify-content: space-evenly;
	border: 1px solid currentColor;
	border-radius: 5px;
}
.series__mobile-options {
	cursor: pointer;
	text-align: center;
	flex-grow: 1;
	padding: 10px;
}
.series__mobile-options--selected {
	background-color: var(--text);
	color: var(--background);
	border: none;
}
.series__details-container {
	list-style: none;
	margin-top: 10px;
	position: relative;
	display: flex;
	flex-direction: column;
	gap: 1.5em;
	padding-top: 1.5em;
}
.series__details-container::before {
	content: "";
	position: absolute;
	top: 0;
	left: 50%;
	width: 80%;
	height: 1px;
	background-color: var(--text);
	transform: translateX(-50%);
}
.series__butons-containers {
	margin-top: 100px;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	align-items: stretch;
	margin-bottom: 15px;
	gap: 10px;
}
.series__cover {
	width: 100vw;
}
.series__volumes-container {
	padding: 10px;
}
.series__volume-item {
	background-color: var(--foreground);
	margin-bottom: 10px;
	list-style: none;
	border-radius: 5px;
	overflow: hidden;
	box-shadow: 2px 2px 3px rgba(0, 0, 0, 0.199);
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-between;
}
.series__volume__image-wrapper {
	display: block;
	position: relative;
	width: 100%;
	overflow: hidden;
	aspect-ratio: auto 465 / 695;
	max-width: 20vw;
}
.series__volume__image {
	width: 100%;
	height: 100%;
}
.series__summary {
	text-indent: 2em;
	text-align: justify;
	line-height: 2em;
}
.series__volume__number {
	font-size: 1.2rem;
	align-self: stretch;
	flex-grow: 1;
	display: flex;
	align-items: center;
	justify-content: center;
	color: var(--text);
	text-align: center;
	text-decoration: none;
}
.checkmark-label {
	display: none;
}
.checkmark {
	width: 2.1rem;
	height: 2.1rem;
	margin-right: 1rem;
	color: var(--green);
	vertical-align: middle;
	-webkit-appearance: none;
	background: none;
	border: 0;
	outline: 0;
	border-radius: 50%;
	transition: 300ms;
	cursor: pointer;
}
.checkmark::before {
	content: "";
	display: block;
	width: inherit;
	height: inherit;
	border-radius: inherit;
	background-size: contain;
	box-shadow: inset 0 0 1px 1px #ccd3d8;
}
.checkmark:checked {
	background-color: currentColor;
}
.checkmark:checked::before {
	box-shadow: none;
	background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24'%3E %3Cpath d='M15.88 8.29L10 14.17l-1.88-1.88a.996.996 0 1 0-1.41 1.41l2.59 2.59c.39.39 1.02.39 1.41 0L17.3 9.7a.996.996 0 0 0 0-1.41c-.39-.39-1.03-.39-1.42 0z' fill='%23fff'/%3E %3C/svg%3E");
}
.series__show-more {
	display: none;
}
.mobile-appearence {
	display: none;
}
.mobile-appearence--show {
	display: block;
}
@media only screen and (min-width: 768px) {
	.mobile-appearence {
		display: block;
	}
	.series {
		display: flex;
		background-color: var(--foreground);
		border-radius: 5px;
		margin: 10px;
		box-shadow: 3px 3px 7px rgba(0, 0, 0, 0.449);
		align-items: flex-start;
	}
	.series__title {
		margin-bottom: 1em;
		font-size: 1.5em;
	}
	.series_main-info {
		padding: 0;
		background-color: var(--foreground);
		position: static;
	}
	.series__image-wrapper {
		background-color: var(--foreground);
		max-width: 360px;
		flex-grow: 1;
		padding: 10px;
		color: var(--text);
		width: 100%;
		border-radius: 5px;
	}
	.series_main-info {
		position: static;
	}
	.series__cover {
		border-radius: 5px;
		max-width: 100%;
	}
	.series_main-info {
		padding: 0px;
	}
	.series__mobile-options-container {
		display: none;
	}
	.series__butons-containers {
		flex-direction: row;
		margin-top: 10px;
	}
	.series__title {
		padding: 0px;
	}
	.series__details-container {
		list-style: none;
		margin-top: 10px;
		position: relative;
	}
	.series__details-container::before {
		display: none;
	}
	.series__volumes-container {
		display: grid;
		grid-template-columns: repeat(4, 1fr);
		padding-top: 10px;
		gap: 10px;
	}
	.series__volume-item {
		display: flex;
		flex-direction: column;
		align-items: center;
		margin: 0px;
		padding: 0px;
	}
	.series__volume__number {
		display: none;
	}
	.series__volume__image-wrapper {
		max-width: none;
		width: 100%;
	}
	.series__volume__image {
		width: 100%;
	}
	.series__summary {
		display: -webkit-box;
		-webkit-box-orient: vertical;
		-webkit-line-clamp: 7;
		overflow: hidden;
	}
	.series__summary--show-full {
		display: block;
	}
	.series__volume__checkmark-container {
		margin: 5px;
		gap: 5px;
	}
	.checkmark {
		margin-left: 5px;
	}
	.series__show-more {
		display: block;
		padding: 10px;
		font-size: 1rem;
		font-weight: bold;
		color: inherit;
		background: linear-gradient(#ffffff00, var(--foreground) 75%);
		position: absolute;
		bottom: 0px;
		z-index: 1;
		width: 100%;
		text-align: center;
		cursor: pointer;
		border: none;
	}

	.series__info-container {
		background-color: var(--foreground);
		overflow: scroll;
		max-width: 800px;
	}
	.checkmark-label {
		display: inline;
		color: var(--text);
	}
}

@media only screen and (min-width: 1024px) {
	.series__volumes-container {
		grid-template-columns: repeat(6, 1fr);
	}
}
