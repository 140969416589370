.profile-header{
    padding-top: 10px;
    gap: 10px;
    display: flex;
    flex-direction: column;

    background: linear-gradient(var(--secundary), var(--primary));
    
    align-items: center;
    justify-content: space-evenly;
    color: white;
    text-shadow: 1px 1px 1px rgba(0, 0, 0, 1);
}
.user-collection{
    position: relative;
    min-height: 50vh;
}
.profile-header__picture{
    aspect-ratio: 1/1;
    border-radius: 50%;
    border: 2px solid white;
    height: 200px;
    background-color: rgb(230, 232, 240);
}
.profile-header__navbar{
    align-self: stretch;

    background-color: var(--secundary);

    overflow-x: scroll;
}
.profile-header__navbar__list{
    padding: 15px;
    display: flex;
    list-style: none;
    flex-direction: row;
}
.profile-header__navbar__link{
    position: relative;
    padding: 15px;
    text-align: center;
    text-decoration: none;
    color: #edf1f5;
    font-weight: bold;
    white-space: nowrap;
}
.profile-header__navbar__link:hover{
    transition: 100ms;
    background-color: #ffffff37;
}
@media only screen and (min-width: 768px) {
    .profile-header__navbar__list{
        justify-content: space-evenly;
    }
}