.form-title{
	font-size: 1.5em;
	padding-top: 15px;
	padding-bottom: 30px;
    color: var(--text);
    text-align: center;
}
.form-container {
	display: flex;
	flex-direction: column;
	gap: 10px;
	background-color: var(--foreground);
    width: 100%;
    min-height: 100vh;
    padding: 40px;
}
.autentication-form {
	display: flex;
	flex-direction: column;
	align-items: stretch;
	color: var(--text);
	gap: 10px;
}
.autentication-form__label {
	display: none;
}
.autentication-form__captcha{
	align-self: center;
}
.form__label--visible{
	margin-top: 10px;
	font-weight: bold;
	color: var(--text);
	align-self: flex-start;
	display: block;
}
.autentication-form__input {
	background-color:  var(--background);
	color: var(--text);
	font-weight: bold;
	padding: 10px;
	border-radius: 3px;
	border: none;
    width: 100%;
	resize: none;
}.autentication-form__input::placeholder{
	font-weight: normal;
}
.errors-message{
	font-weight: bold;
	display: flex;
	position: absolute;
	top: 20px;
	left: 50%;
	line-height: 1.5em;
	padding: 15px;
	gap: 15px;
	color: #f56c6c;
	background-color: #fef0f0;
	border: 1px solid #fde2e2;
	border-radius: 4px;
	align-items: center;
	translate: -50%;
	width: 420px;
	max-width: 80vw;
}
.errors-message__error{
	margin: 0.5rem;
}
.autentication-form__link{
	color: var(--accent);
	text-decoration: underline;
}
.autentication-form__icon{
	margin-right: 8px;
	margin-left: -4px;
	width: 16px;
	height: 16px;
}
.autentication-form__button--google{
	display: flex;
	justify-content: center;
	text-align: center;
	align-items: center;
}
@media only screen and (min-width: 768px){
	.form-container {
		max-width: 400px;
		margin: auto;
		margin-top: 40px;
		height: auto;
		min-height: auto;
		border-radius: 4px;
		box-shadow: 3px 3px 10px rgba(0, 0, 0, 0.3);
	}
}