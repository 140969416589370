.browse-collection-page {
	background-color: var(--background);
	min-height: 100vh;
}
.form {
	width: 100%;
	padding: 10px;
	display: flex;

}
.form__input {
	background-color: var(--foreground);
	color: var(--text);
	font-weight: bold;
	padding: 10px;
	border-radius: 3px;
	border: none;
	outline: none;
}
.form__input::placeholder{
	font-weight: normal;
}
.form__input__grow {
	flex-grow: 1;
}
.form__label {
	display: none;
}
.collection-container {
	list-style: none;
	margin: auto;
	padding: 10px 10px;
	display: grid;
	grid-template-columns: repeat(3, 1fr);
	justify-content: space-evenly;
	gap: 20px 10px;
    overflow: scroll;
}
.not-found-message{
	text-align: center;
	color: var(--text);
	position: absolute;
	top: 50%;
	left: 50%;
	translate: -50% -50%;
	max-width: 600px;
}.not-found-message > a{
	text-decoration: underline;
}
@media only screen and (max-width: 320px) {
    .collection-container{
        grid-template-columns: repeat(2, 1fr);
    }
}


@media only screen and (min-width: 768px) {
    .collection-container{
        grid-template-columns: repeat(4, 1fr);
    }
}

@media only screen and (min-width: 1024px) {
    .collection-container{
        grid-template-columns: repeat(6, 1fr);
    }
}