.home {
	margin: auto;
	margin-bottom: 2em;
	position: relative;
	text-align: center;
	font-size: 1em;
	padding: 70px 50px;
	color: var(--text);
	background-color: var(--foreground);
	max-width: 1100px;
	box-shadow: 3px 3px 10px rgba(0, 0, 0, 0.3);

}
.home__main-description {
	line-height: 1.5rem;
	margin: 1rem 0;
}
.home__features-container {
	display: grid;
	grid-template-columns: repeat(1, 1fr);
	gap: 1.5rem;
	justify-items: center;
}
.home__feature {
	text-align: left;
	display: flex;
	gap: 1em;
}
.home__icon__container {
	background-color: var(--primary);
	display: flex;
	color: white;
	align-items: center;
	aspect-ratio: 1/1;
	justify-content: center;
	height: 2rem;
	border-radius: 0.5rem;
}
.feature__title {
	font-size: 1.17em;
	line-height: 1.5rem;
}
.feature__description {
	margin-top: 1rem;
	line-height: 1rem;
	max-width: 500px;
}
.call-to-action-container {
	display: flex;
	position: absolute;
	gap: 20px;
	bottom: 0;
	left: 50%;
	translate: -50% 50%;
}
.call-to-action {
	padding: 10px;
	background-color: var(--primary);
	color: white;
	font-weight: bold;
	border-radius: 3rem;
}

@media only screen and (min-width: 768px) {
	.home__features-container {
		grid-template-columns: repeat(2, 1fr);
	}
}
@media only screen and (min-width: 1040px) {
	.home {
		margin-top: 15px;
		border-radius: 10px;
	}
}
