.about-page{
    margin: auto;
	margin-bottom: 2em;
    margin-top: 2em;
	position: relative;
	font-size: 1em;
	padding: 70px 2em;
	color: var(--text);
	background-color: var(--foreground);
	max-width: 700px;
    text-align: justify;
    box-shadow: 2px 2px 7px rgba(0, 0, 0, 0.282);
}
.about-page h1{
    margin-bottom: 2rem;
    text-align: center;
    font-size: 2rem;
}
.about-page ul{
    margin-top: 1rem;
    margin-left: 2rem;
}
.about-page li{
    margin-top: 1rem;
}
.about-page h2, .about-page  h3{
    margin-top: 1rem;
}
.about-page p{
    max-width: 600px;
    margin-top: 1rem;
    text-indent: 2rem;
    line-height: 1.5rem;
}
.about-page a{
    color: var(--accent);
}
.about-page__donation-button{
    text-align: center;
    margin: 25px;
}
@media only screen and (min-width: 1040px) {
	.about-page {
		margin-top: 2em;
		border-radius: 10px;
	}
}