.series-card__title {
	font-size: 0.8em;
	color: var(--text);
	display: -webkit-box;
	-webkit-box-orient: vertical;
	-webkit-line-clamp: 2;
	overflow: hidden;
	margin-top: 10px;
}
.series-card__image-container {
	display: block;
	position: relative;
	border-radius: 5px;
	width: 100%;
	overflow: hidden;
	aspect-ratio: auto 465 / 695;
	background-color: rgba(var(--gray));
}
.series-card__loader {
	color: #00000000;
	position: relative;
	overflow: hidden;
	background-color: rgba(var(--gray));
	border-radius: 2px;
	margin-bottom: 0.2em;
}
.series-card__loader::after {
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	background-image: linear-gradient(
		90deg,
		rgba(var(--gray), 0) 0,
		rgba(var(--light-blue), 0.06) 40%,
		rgba(var(--light-blue), 0.06) 60%,
		rgba(var(--gray), 0)
	);
	transform: translateX(-100%);
	animation: shimmer 2s infinite;
	content: "";
}
@keyframes shimmer {
	100% {
		transform: translateX(100%);
	}
}
.series-card__overlay:hover > * {
	opacity: 1;
	transition: 500ms;
}
.series-card__img {
	position: absolute;
	left: 50%;
	z-index: 0;
	translate: -50%;
	width: auto;
	min-width: 100%;
	height: 100%;
	transition: opacity 0.3s ease-in-out ;
	opacity: 1;
}
.series-card__img--loading{
	opacity: 0;
}
.series-card__bar {
	position: absolute;
	z-index: 1;
	background-color: rgb(var(--yellow), 0.25);
	width: 100%;
	height: 5px;
	bottom: 0;
}
.series-card__progress-bar {
	position: absolute;
	z-index: 2;
	background-color: rgb(var(--yellow));
	height: 5px;
	bottom: 0;
}
.series-card__progress-bar--completed {
	background-color: var(--green);
}
