.prompt-confirm {
	font-size: 1.5em;
	text-align: center;
	font-weight: bold;
	position: fixed;
	top: 50%;
	left: 50%;
	line-height: 1.5em;
	padding: 15px;
	color: var(--text);
	background-color: var(--background);
	box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.4);
	border-radius: 4px;
	translate: -50% -50%;
	width: 420px;
	max-width: 80vw;
	z-index: 999;
}
.prompt-confirm__button {
	cursor: pointer;
	padding: 15px;
	margin: 10px;
	border-radius: 4px;
	border: none;
	background-color: var(--green);
	color: white;
	width: 40%;
	font-weight: bold;
}
.prompt-confirm__button--cancel {
	background-color: var(--red);
}

.message_box {
	font-weight: bold;
	display: flex;
	position: absolute;
	top: 20px;
	left: 50%;
	line-height: 1.5em;
	padding: 15px;
	gap: 15px;
	border-radius: 4px;
	align-items: center;
	translate: -50%;
	width: 420px;
	max-width: 80vw;
	box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.3);
	z-index: 999;
}
.message_box--error {
	color: #f56c6c;
	background-color: #fef0f0;
	border: 1px solid #fde2e2;
}
.message_box--warning {
	color: hsl(50, 80%, 49%);
	background-color: hsl(50, 88%, 97%);
	border: 1px solid hsl(50, 87%, 70%);
}
.message_box--success {
	color: hsl(100, 60%, 49%);
	background-color: hsl(100, 88%, 97%);
	border: 1px solid hsl(100, 87%, 70%);
}
.message_box__message {
	margin: 0.5rem;
}
@media only screen and (min-width: 768px) {
	.message_box {
		top: 50px;
	}
}
