::-webkit-scrollbar {
	display: none;
}
.volume {
	position: relative;
	background-color:  var(--background);
}
.volume__cover-wrapper {
	position: relative;
}
.volume__main-info {
	padding: 10px;
	width: 100%;
	color: var(--text);
	background: linear-gradient(#ffffff00,  var(--background) 120px);
	position: absolute;
	bottom: 0;
}
.volume__cover {
	width: 100vw;
}
.volume__info-container {
	padding: 10px;
	width: 100%;
	color: var(--text);
	background-color: var(--background);
}
.volume__functions {
	margin-top: 100px;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	align-items: stretch;
	margin-bottom: 15px;
	gap: 10px;
}
.volume__details-container {
	list-style: none;
	margin-top: 10px;
	position: relative;
	display: flex;
	flex-direction: column;
	gap: 1.5em;
	padding-top: 1.5em;
}
.volume__details-container::before {
	content: "";
	position: absolute;
	top: 0;
	left: 50%;
	width: 80%;
	height: 1px;
	background-color: var(--text);
	transform: translateX(-50%);
}
.volume__summary {
	text-indent: 2rem;
	text-align: justify;
	line-height: 2em;
}
@media only screen and (min-width: 768px) {
	.volume {
		display: flex;
		background-color: var(--foreground);
		border-radius: 5px;
		margin: 10px;
		box-shadow: 3px 3px 7px rgba(0, 0, 0, 0.449);
		overflow: hidden;
	}
	.volume__cover {
		border-radius: 5px;
		max-width: 100%;
	}
	.volume__main-info {
		padding: 0;
		background-color: var(--foreground);
		position: static;
	}
	.volume__cover-wrapper {
		flex-grow: 1;
		padding: 10px;
		color: var(--text);
		max-width: 360px;
		width: 100%;
	}
	.volume__title {
		font-size: 1.5em;
	}
	.volume__info-container {
		position: static;
		background-color: var(--foreground);
		overflow: scroll;
		max-width: 800px;
	}
	.volume__details-container {
		list-style: none;
		margin-top: 10px;
		position: relative;
	}
	.volume__details-container::before {
		display: none;
	}
	.volume__functions {
		flex-direction: row;
		margin-top: 10px;
	}
	.volume__series-button {
		text-align: center;
		margin-top: 0;
	}
}
