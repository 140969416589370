.menu {
	color: var(--text);
	position: fixed;
	bottom: 25px;
	right: 20px;
	z-index: 99;
	cursor: pointer;
}
.mobile_menu {
	display: flex;
	width: 50px;
	height: 50px;
	border-radius: 6px;
	box-shadow: 0 1px 20px rgba(0, 0, 0, 1);
	background-color: var(--background);
	align-items: center;
	justify-content: center;
}

.navbar {
    list-style: none;
	width: 0px;
	height: 0px;
	display: grid;
	grid-template-columns: repeat(3, 1fr);
	opacity: 0;
	pointer-events: none;
	box-shadow: 0 1px 20px #0000004d;
	border-radius: 5px;
	justify-items: center;
	align-items: center;
	padding: 8px;
}
.mobile--visible {
	pointer-events: auto;
	height: 225px;
	width: 230px;
	opacity: 1;
	transition: 0.2s;
	background-color: var(--background);
}
.navbar__button {
	color: var(--text);
	text-align: center;
	font-weight: bold;
}
.navbar__label {
	white-space: nowrap;
	font-size: 0.8em;
	display: block;
	cursor: pointer;
}
.navbar__icon--theme{
	font-size: 1.25em;
    line-height: 0.05em;
}

@media only screen and (min-width: 1040px) {
    .menu {
        color: var(--text);
        position: relative;
        bottom: 0px;
        left: 0px;
        cursor: auto;
    }
	.navbar {
		display: flex;
		width: 100%;
		height: auto;
		padding: 15px;
		border-radius: 0;
		box-shadow: none;
		color: white;
		background-color: var(--primary);
		justify-content: space-evenly;
        opacity: 1;
		pointer-events: auto;
	}
    .navbar__label{
        color: white;
    }
    .navbar__label:hover{
		text-decoration: underline;
    }
    .navbar__icon{
        display: none;
    }
	.navbar__icon--theme{
		color: white;
	}
	.navbar__label--theme{
		display: none;
	}
	.navbar__button--theme{
		border: 1px solid white;
		padding: 5px;
		padding-bottom: 1px;
		border-radius: 5px;
	}
}